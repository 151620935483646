<template>
  <BaseLayout wrapperClass="checkout">
    <Breadcrumbs />
    <section class="checkout-details pt-3">
      <b-container>
        <div class="row">
          <b-col lg="4">
            <NotLoggedInCheckoutDetails v-if="!isLoggedIn" />
            <LoggedInCheckoutDetails v-else />
          </b-col>
          <b-col lg="4" class="pt-20 pt-lg-0">
            <div class="group">
              <div class="group-title d-flex align-items-center mb-5">
                <div class="delivery-icon mr-8">
                  <img src="@/base/assets/delivery-icon.png" alt="delivery" />
                </div>
                <span>{{ $t("shipping_method") }}</span>
              </div>
              <div v-if="deliveryOptions.length == 0">
                {{ $t("fill_address_information") }}
              </div>
              <div v-if="deliveryOptions.length !== 0">
                <ul class="delivery-options-list list-unstyled pl-0 m-0">
                  <li
                    v-for="(deliveryOption, index) of deliveryOptions"
                    :key="`ship-${index}`"
                  >
                    <b-form-radio
                      class="pl-30"
                      v-model="selectedShippingMethod"
                      name="deliveryOption"
                      :value="`${deliveryOption.carrier_code}|${deliveryOption.method_code}`"
                    >
                      <div class="d-flex">
                        <span class="mx-6 delivery-price d-block"
                          >{{
                            formatCurrency(deliveryOption.amount.value)
                          }}&nbsp;</span
                        >
                        <span class="d-block mx-6"
                          >{{ deliveryOption.carrier_title }} ({{
                            deliveryOption.method_title
                          }})</span
                        >
                      </div>
                    </b-form-radio>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="showPickupLocation">
              <span class="d-block mb-15">{{ $t("select_store") }}</span>
              <b-form-group class="select--custom">
                <b-form-select
                  v-model="pickupLocation"
                  :options="pickupLocations"
                  class="mb-3"
                  value-field="pickup_location_code"
                  text-field="name"
                  id="pickup-dropdown"
                >
                  <template #first>
                    <b-form-select-option :value="null">{{
                      $t("select_store")
                    }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <div>
              <div
                class="address-list store-address"
                v-if="showPickupLocation && selectedPickupLocation"
              >
                <div class="address-list-wrap">
                  <div class="address-item" v-if="selectedAddress != null">
                    <label>{{ pickupLocationName }}</label>
                    <div>{{ selectedAddress.street[0] }}</div>
                    <div>
                      {{ selectedAddress.postcode }} {{ selectedAddress.city }}
                    </div>
                    <div v-if="selectedAddress.country">
                      {{ selectedAddress.countryName }}
                    </div>
                    <div>
                      T:<a :href="`tel:` + pickupLocationPhone">
                        {{ pickupLocationPhone }}</a
                      >
                    </div>
                    <div v-if="selectedAddress.vat_id != null">
                      VAT:{{ selectedAddress.vat_id }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="group">
              <div class="group-title d-flex align-items-center mb-5">
                <div class="payment-icon mr-8">
                  <img src="@/base/assets/payment-icon.png" alt="payment" />
                </div>
                <span class="d-block">{{ $t("payment_method") }}</span>
              </div>
              <div v-if="deliveryOptions.length == 0 || shippingMethod == null">
                {{ $t("fill_shipping_options") }}
              </div>
              <div v-else>
                <ul class="payment-options-list list-unstyled p-0 m-0">
                  <li
                    v-for="(paymentOption, index) in paymentOptions"
                    :key="`pay-${index}`"
                  >
                    <b-form-radio
                      class="pl-30"
                      name="paymentOption"
                      :value="paymentOption.code"
                      v-on:change="checkAllSubOptions(paymentOption.code)"
                      v-model="selectedPaymentMethod"
                    >
                      <div class="d-flex align-items-center">
                        <div class="payment-option-img">
                          <img
                            :src="`${app_url}/media/graphql/icons/50x32/${paymentOption.code}.png`"
                            :alt="paymentOption.code"
                          />
                        </div>
                        <span>{{ paymentOption.label }}</span>
                      </div>
                    </b-form-radio>
                    <div
                      :class="isKlarnaSelected"
                      v-if="paymentOption.code == 'multisafepay_klarna'"
                    >
                      Je moet minimaal 18+ zijn om deze dienst te gebruiken. Als
                      je op tijd betaalt, voorkom je extra kosten.
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col lg="4" class="pt-30 pt-lg-0">
            <div class="bg-gray py-10 px-20">
              <span class="group-title d-block"
                ><i class="lnr lnr-checkmark-circle mr-10"></i
                >{{ $t("order_overview") }}</span
              >
              <div class="cart-details pb-15">
                <b-link
                  href="#"
                  v-b-toggle.cart-details-collapse
                  class="d-block mb-10 group-subtitle border-0 collapse-opener"
                  v-html="getItemsInCart"
                ></b-link>
                <b-collapse id="cart-details-collapse" class="bg-white" visible>
                  <div
                    class="d-flex align-items-center py-20"
                    v-for="cartItem of cartItems"
                    :key="cartItem.id"
                  >
                    <div class="img">
                      <img
                        :src="cartItem.product.image.small"
                        :alt="cartItem.product.name"
                      />
                    </div>
                    <div class="details">
                      <span class="name d-block mb-20">{{
                        cartItem.product.name
                      }}</span>
                      <div
                        v-if="
                          typeof cartItem.configurable_options != 'undefined'
                        "
                      >
                        <span
                          v-for="(
                            option, index
                          ) of cartItem.configurable_options"
                          :key="index"
                          class="product-options d-block"
                        >
                          <span class="product-options-title"
                            >{{ option.option_label }}:&nbsp;</span
                          >
                          <span class="product-options-value">{{
                            option.value_label
                          }}</span>
                        </span>
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="quantity-input d-flex mr-5">
                          <input
                            type="number"
                            min="1"
                            :value="cartItem.quantity"
                            name="quantity"
                            ref="inputQuantity"
                            readonly="readonly"
                          />
                        </div>
                        <div>
                          {{
                            formatCurrency(
                              cartItem.prices.row_total_including_tax.value
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
              <div class="order-overview">
                <span class="group-subtitle pb-15 d-block">{{
                  $t("order_overview")
                }}</span>
                <ul class="cost-list list-unstyled pl-0 m-0">
                  <li class="d-flex justify-content-between">
                    <span class="d-block mr-20">{{ $t("subtotal") }}</span>
                    <span class="d-block">
                      {{ formatCurrency(cartSubTotalPrice) }}</span
                    >
                  </li>
                  <li
                    v-if="shippingMethod != null"
                    class="d-flex justify-content-between"
                  >
                    <span class="d-block mr-20"
                      >{{ shippingMethod.carrier_title }} /
                      {{ shippingMethod.method_title }}</span
                    >
                    <span
                      class="d-block"
                      v-if="shippingMethod.amount_including_tax"
                    >
                      {{
                        formatCurrency(
                          shippingMethod.amount_including_tax.value
                        )
                      }}</span
                    >
                  </li>
                  <li
                    v-for="(price, index) in cartTotalDiscounts"
                    :key="`price-${index}`"
                    class="d-flex justify-content-between"
                  >
                    <span class="d-block mr-20">{{ price.label }}</span>
                    <span class="d-block"
                      >- {{ formatCurrency(price.amount.value) }}</span
                    >
                  </li>
                </ul>
                <div class="total-grp d-flex justify-content-between">
                  <span class="total">{{ $t("total") }}</span>
                  <span class="text-blue">
                    {{ formatCurrency(cartTotalPrice) }}</span
                  >
                </div>
                <ul class="cost-list list-unstyled pl-0 m-0 pb-0">
                  <li
                    v-for="(price, index) in cartTotalTaxPrices"
                    :key="`tax-${index}`"
                    class="d-flex justify-content-between"
                  >
                    <span class="d-block mr-20">{{ price.label }}</span>
                    <span class="d-block" v-if="price">{{
                      formatCurrency(price.amount.value)
                    }}</span>
                  </li>
                </ul>
              </div>
              <div class="coupon pt-15 mb-15">
                <b-link
                  class="collapse-opener group-subtitle d-block mb-10 border-0"
                  v-b-toggle.coupon-collapse
                  >{{ $t("coupon_discount") }}</b-link
                >
                <b-collapse id="coupon-collapse" collapsed class="pb-15">
                  <div class="d-flex">
                    <b-form-input
                      v-model="couponCode"
                      type="text"
                      :placeholder="$t('fill_out_discount_code')"
                    ></b-form-input>
                    <b-button @click="addCoupon" class="py-3">{{
                      $t("add")
                    }}</b-button>
                  </div>
                  <div
                    v-if="appliedCouponCodes && appliedCouponCodes[0]"
                    class="pt-10"
                  >
                    {{ $t("applied_coupon_code") }}:
                    {{ appliedCouponCodes[0].code }}
                    <b-link
                      href="#"
                      @click="removeCouponCode()"
                      class="lnr lnr-cross pl-5"
                    ></b-link>
                  </div>
                </b-collapse>
              </div>
              <b-form-checkbox
                class="pl-30"
                id="newsletter"
                v-model="SubscribeNewsletter"
                name="news-letter-subscribe"
                value="yes"
                unchecked-value="no"
                >{{ $t("subscription_newsletter") }}</b-form-checkbox
              >
              <span class="terms">{{ $t("terms_warning") }}</span>
              <b-button
                @click="submitForm"
                v-bind:disabled="buttonClicked"
                variant="info"
                class="text-light w-100 text-uppercase checkout-btn"
                >{{ $t("pay") }}</b-button
              >
            </div>
          </b-col>
        </div>
      </b-container>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_thehague_cyclewearonline/core/components/BaseLayout";
import NotLoggedInCheckoutDetails from "@/base/core/components/NotLoggedInCheckoutDetails";
import LoggedInCheckoutDetails from "@/base/core/components/LoggedInCheckoutDetails";
import Breadcrumbs from "@/base/core/components/Breadcrumbs";
import config from "@config";
import Cart from "@storefront/core/modules/cart/mixins";

export default {
  name: "CheckoutPage",
  components: {
    BaseLayout,
    NotLoggedInCheckoutDetails,
    LoggedInCheckoutDetails,
    Breadcrumbs,
  },
  mixins: [Cart],
  computed: {
    getItemsInCart() {
      let items = this.$t("items_in_cart", { numberOf: this.cartCount });
      items = items.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
      return items;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    paymentOptions() {
      return this.$store.getters["cart/getPaymentMethods"];
    },
    selectedAddress: {
      get: function () {
        let address = { ...this.$store.getters["cart/getShippingAddress"] };
        return address;
      },
      set: function () {
        //Nothing to do
      },
    },
    deliveryOptions() {
      let options = [...this.$store.getters["cart/getShippingMethods"]];
      if (options.length > 0) {
        const pickIndex = options.findIndex(
          (option) => option.carrier_code === "instore"
        );
        options.push(options.splice(pickIndex, 1)[0]);
      } else {
        return [];
      }
      return options;
    },
    selectedPaymentMethod: {
      get: function () {
        return this.$store.getters["cart/getPaymentMethod"];
      },
      set: function (method) {
        this.updateLocalStorage({ billing: method });
        this.$store.commit("cart/setPaymentMethod", method);
        if (this.$gtm.enabled()) {
          let prod = [];
          this.cartItems.forEach((element) => {
            let i = {
              id: element.product.sku,
              name: element.product.name,
              quantity: element.quantity,
            };
            prod.push(i);
          });
          window.dataLayer.push({
            event: "eec.checkout",
            ecommerce: {
              checkout: {
                actionField: {
                  step: 2,
                  option: method,
                  action: "checkout",
                },
                products: prod,
              },
            },
          });
        }
      },
    },
    btnDisable() {
      if (this.$store.getters["cart/getShippingAddress"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getBillingAddress"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getShippingMethod"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getPaymentMethod"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getEmail"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getItemsTotalQuantity"] == 0) {
        return true;
      }

      return false;
    },
    pickupLocations() {
      let locations = [...this.$store.getters["cart/getPickupLocations"]];
      locations = locations.map((location) => {
        if (location.name.startsWith("Wieleroutfits")) return location;
        return {
          ...location,
          name: `Wieleroutfits ${location.name}`,
          phone: location.phone,
        };
      });

      let newLocations = [];
      let sortVal = config.pickupSortOptions;
      sortVal.forEach((item) => {
        const index = locations.findIndex(
          (location) =>
            location.pickup_location_code.toLowerCase() == item.toLowerCase()
        );
        if (index > -1) newLocations.push(locations.splice(index, 1)[0]);
      });

      newLocations = [...newLocations, ...locations];

      return newLocations;
    },
    pickupLocationName() {
      if (this.pickupLocation && this.pickupLocation.length > 0) {
        return this.pickupLocations.find(
          (loc) => loc.pickup_location_code === this.pickupLocation
        ).name;
      } else {
        return "";
      }
    },
    pickupLocationPhone() {
      if (this.pickupLocation && this.pickupLocation.length > 0) {
        return this.pickupLocations.find(
          (loc) => loc.pickup_location_code === this.pickupLocation
        ).phone;
      } else {
        return "";
      }
    },
    getSelectedShippingMethod() {
      const shmed = this.$store.getters["cart/getShippingMethod"];
      return shmed;
    },
    shippingMethod: {
      get() {
        return this.$store.getters["cart/getShippingMethod"];
      },
      set(shippingMethod) {
        return shippingMethod;
      },
    },
    appliedCouponCodes() {
      return this.$store.getters["cart/getAppliedCouponCodes"];
    },
    showPaymentSuboptions: {
      get() {
        return "collapse hidden";
      },
      set() {},
    },
    isKlarnaSelected: {
      get() {
        return this.selectedPaymentMethod == "multisafepay_klarna"
          ? "collapse show"
          : "collapse hidden";
      },
      set() {},
    },
  },
  watch: {
    showPickupLocation() {
      this.setShippingMethod();
    },
    async pickupLocation(code) {
      const response = await this.$store.dispatch("cart/setPickupLocation", {
        code,
      });
      if (response) {
        const delOption = this.selectedShippingMethod.split("|");
        await this.$store.dispatch("cart/addShippingMethod", {
          carrier: delOption[0],
          method: delOption[1],
        });
        this.selectedPickupLocation = await this.$store.getters[
          "cart/getShippingAddress"
        ];
        this.updateLocalStorage({
          shipping: this.selectedShippingMethod,
          delivery: code,
        });
        this.$emit("toggleNextDisable", false);
      }
    },
    selectedShippingMethod(method) {
      const [carrier_code, method_code] = method.split("|");

      if (carrier_code == "instore" && method_code == "pickup") {
        this.showPickupLocation = true;
      } else {
        this.updateLocalStorage({
          shipping: this.selectedShippingMethod,
          delivery: null,
        });
        this.showPickupLocation = false;
        this.$store.dispatch("cart/addShippingMethod", {
          carrier: carrier_code,
          method: method_code,
        });
        if (this.$gtm.enabled()) {
          let prod = [];
          this.cartItems.forEach((element) => {
            let i = {
              id: element.product.sku,
              name: element.product.name,
              quantity: element.quantity,
            };
            prod.push(i);
          });
          window.dataLayer.push({
            event: "eec.checkout",
            ecommerce: {
              checkout: {
                actionField: {
                  step: 3,
                  option: method,
                  action: "checkout",
                },
                products: prod,
              },
            },
          });
        }
      }
    },
    SubscribeNewsletter() {
      this.updateLocalStorage({ newsletter: this.SubscribeNewsletter });
    },
  },
  created() {},
  mounted() {
    const shmed = this.getSelectedShippingMethod;
    if (shmed != null) {
      if (shmed.carrier_code == "instore" && shmed.method_code == "pickup") {
        this.showPickupLocation = true;
      }
      this.selectedShippingMethod =
        shmed.carrier_code + "|" + shmed.method_code;
    }
    this.setShippingMethod();
    const bcrumb = { current: this.$t("checkout"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
    if (this.$gtm.enabled()) {
      let prod = [];
      this.cartItems.forEach((element) => {
        let i = {
          id: element.product.sku,
          name: element.product.name,
          quantity: element.quantity,
        };
        prod.push(i);
      });
      window.dataLayer.push({
        event: "eec.checkout",
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
              option: "",
              action: "checkout",
            },
            products: prod,
          },
        },
      });
    }
    this.storage();
  },

  methods: {
    storage() {
      this.localStorageData =
        JSON.parse(localStorage.getItem("checkoutPayment")) ?? {};
      this.localStorageData.shipping
        ? (this.selectedShippingMethod = this.localStorageData.shipping)
        : "";
      this.localStorageData.delivery
        ? ((this.pickupLocation = this.localStorageData.delivery),
          (this.showPickupLocation = true),
          (this.selectedPickupLocation = this.localStorageData.delivery))
        : "";
      this.localStorageData.billing
        ? (this.selectedPaymentMethod = this.localStorageData.billing)
        : null;
      this.localStorageData.newsletter
        ? (this.SubscribeNewsletter = this.localStorageData.newsletter)
        : (this.SubscribeNewsletter = "yes");
    },
    formatCurrency(amount) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(amount);
    },
    async submitForm() {
      this.buttonClicked = true;
      this.checkSiblingFields();
      if (this.checkFields()) {
        this.buttonClicked = true;
        const retval = await this.$store.dispatch("cart/addPaymentMethod");
        if (retval != false) {
          localStorage.removeItem("orderHash");
          if (retval.order.hash) {
            localStorage.setItem("orderHash", retval.order.hash);
          }

          const url = retval.redirect_url.replace(config.app_url, "");
          if (this.isLoggedIn == true) {
            this.$store.dispatch("user/loadOrders");
          }
          if (url.includes("https://")) {
            window.location = url;
          } else {
            this.$store.dispatch("cart/loadCart");
            this.$router.push({
              path: url + "?transactionid=" + retval.order.increment_id,
              params: { hash: retval.order.hash },
            });
          }
        }
        this.buttonClicked = false; //Re-enable button when addPaymentMethod fails
      }
      this.buttonClicked = false; //Re-enable button when addPaymentMethod fails
    },
    async addCoupon() {
      if (this.couponCode != "") {
        const retval = await this.$store.dispatch("cart/addCouponCode", {
          code: this.couponCode,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("coupon_code"),
            text: this.$t("coupon_code_ok", { code: this.couponCode }),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.appliedCouponCode = this.couponCode;
          this.couponCode = "";
        }
      }
    },
    checkAllSubOptions(code) {
      this.showPaymentSuboptions = "collapse hidden";
      if (code == "multisafepay_klarna") {
        this.isKlarnaSelected = "collapse show";
      } else {
        this.isKlarnaSelected = "collapse hidden";
      }
      this.isPaymentSelected = true;
      this.showPaymentRequired = false;
    },
    submitCoupon(e) {
      e.preventDefault();
    },
    setShippingMethod() {
      if (this.showPickupLocation == false) {
        this.shippingMethod = this.selectedAddress;
      } else {
        if (this.user.addresses) {
          const defaultShipping = this.user.defaultShipping;
          const address = this.user.addresses.find(
            (add) => add.id == defaultShipping
          );
          this.shippingMethod = address;
        } else {
          this.shippingMethod = this.selectedAddress;
        }
      }
    },
    clearLocalStorage() {
      localStorage.removeItem("checkout");
    },
    updateLocalStorage(data) {
      this.localStorageData = Object.assign({}, this.localStorageData, data);
      localStorage.setItem(
        "checkoutPayment",
        JSON.stringify(this.localStorageData)
      );
    },
    async removeCouponCode() {
      await this.$store.dispatch("cart/removeCouponCode");
    },
    checkFields() {
      var errorFields = [];
      var storeValidation = this.$store.getters["cart/getStoreValidation"];
      for (const property in storeValidation) {
        storeValidation[property].forEach((error) => {
          errorFields.push(error);
        });
      }
      if (errorFields.length > 0) {
        const msg = {
          type: "danger",
          title: this.$t("Checkout fields incomplete"),
          text: this.$t("The following fields are invalid:", {
            errors: errorFields.join(", "),
          }),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
        return false;
      }
      return true;
    },
    checkSiblingFields() {
      this.$root.$emit("checkFields");
      var errorFields = [];
      if (!this.shippingMethod) {
        errorFields.push(this.$t("Shipping method"));
      }
      if (!this.selectedPaymentMethod && !this.getSelectedPaymentMethod) {
        errorFields.push(this.$t("Payment method"));
      } else if (!this.selectedPaymentMethod) {
        this.selectedPaymentMethod = this.getSelectedPaymentMethod;
      }
      if (errorFields.length > 0) {
        this.$store.commit("cart/setStoreValidation", {
          payment: errorFields,
        });
      } else {
        this.$store.commit("cart/setStoreValidation", {
          payment: [],
        });
      }
    },
  },

  data() {
    return {
      localStorageData: {},
      selectedShippingMethod: null,
      pickupLocation: null,
      showPickupLocation: false,
      selectedPickupLocation: null,
      couponCode: "",
      app_url: config.app_url,
      paymentSubOptions: {},
      SubscribeNewsletter: "yes",
      carrier_code: "",
      buttonClicked: false,
    };
  },
  metaInfo() {
    return {
      title: "Checkout",
      // all titles will be injected into this template
    };
  },
};
</script>


